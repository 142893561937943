import {
  AddRounded,
  Lock,
  ManageAccountsRounded,
  Phone,
  PhoneAndroid,
  SupervisedUserCircle,
} from '@mui/icons-material';
import { filter } from 'lodash';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';

import Scrollbar from '../components/scrollbar/index';
import {
  AccessListHead,
  AccessListToolbar,
} from '../section/@dashboard/access/index';
import compteReducer from '../redux/reducer/compteReducer';
import httpService from '../services/httpService';
import { urlapi } from '../config/url';
import { ADD_ACCESS, FETCH_ACCOUNT } from '../redux/actions/actionsType';
import FormSelect from '../components/FormSelect';
import FormTextField from '../components/FormTextField';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (access) =>
        access._id.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        access.compte.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  { id: 'name', label: 'id', alignRight: false },
  { id: 'compte', label: 'Compte', alignRight: false },
  { id: 'login', label: 'Login', alignRight: false },
  { id: 'password', label: 'Mot de passe', alignRight: false },
];
function Access() {
  const INITIAL_VALUES = {
    _id: '',
    login: '',
    password: '',
  };

  const ACCESS_SCHEMA = Yup.object().shape({
    _id: Yup.string().required(),
    login: Yup.string().required(),
    password: Yup.string().required(),
  });
  const [open, setOpen] = React.useState(false);
  const [state, dispatch] = useReducer(compteReducer, []);
  const [load, setload] = useState({ status: false, progress: 0 });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = state.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, email) => {
    const selectedIndex = selected.indexOf(email);

    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - state.length) : 0;

  const filteredAccess = applySortFilter(
    state,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredAccess.length && !!filterName;
  useEffect(() => {
    getAllComptes();
  }, []);

  const getAllComptes = async () => {
    setload({ ...load, status: true });
    try {
      const { data } = await httpService.get(`${urlapi}/comptes/allCompte`, {
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setload({ ...load, progress: percentCompleted });
        },
      });
      dispatch({ type: FETCH_ACCOUNT, payload: data });
      setload({ ...load, status: false });
    } catch (error) {
      console.log(error);
    }
  };

  const Submit = async (value, { resetForm }) => {
    dispatch({ type: ADD_ACCESS, payload: value });
    resetForm();
    try {
      const { data } = await httpService.post(
        `${urlapi}/access/new`,
        { ...value },
        {
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            const percent = percentCompleted;
            setload({ ...load, progress: percent });
          },
        }
      );
      setload({ ...load, status: false });
    } catch (error) {
      toast.error();
    }
  };
  return load.status ? (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        // transform: 'translate(-50%,-50%)',
        width: '100%',
        height: '100%',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      sx={{ padding: 2, display: 'flex', flexGrow: 1, flexDirection: 'column' }}
    >
      <Typography fontSize={26} fontWeight={'bold'}>
        Access Items
      </Typography>
      <IconButton
        sx={{
          marginTop: 2,
          backgroundColor: 'secondary.main',
          color: 'white',
          paddingRight: 2,
          borderRadius: 1,
          fontSize: 16,
          width: '10%',
        }}
        onClick={handleOpen}
      >
        <AddRounded sx={{ color: 'white' }} />
        Ajouter
      </IconButton>
      <Card sx={{ borderRadius: 3, mt: 5, boxShadow: 3 }}>
        <AccessListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />
        <Box>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <AccessListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={state.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredAccess
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const { _id, nom, prenom, access } = row;
                    const selectedAccess = selected.indexOf(_id) !== -1;
                    console.log(access);
                    return (
                      access.length > 0 &&
                      access.map((myaccess) => (
                        <TableRow
                          hover
                          key={myaccess._id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedAccess}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              sx={{ color: 'secondary.main' }}
                              checked={selectedAccess}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {_id}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{`${nom} ${prenom}`}</TableCell>
                          <TableCell align="left">{myaccess.login}</TableCell>
                          <TableCell align="left">
                            {myaccess.password}
                          </TableCell>
                        </TableRow>
                      ))
                    );
                  })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={state.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Nouvelle access
          </Typography>
          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            <Formik
              initialValues={INITIAL_VALUES}
              onSubmit={Submit}
              validationSchema={ACCESS_SCHEMA}
            >
              {({ handleSubmit }) => (
                <FormControl sx={{ minWidth: '100%' }}>
                  <InputLabel htmlFor="grouped-native-select">
                    Compte
                  </InputLabel>
                  <FormSelect myarray={state} name="_id" show={'nom'} />
                  <FormTextField
                    sx={{ width: '100%', mt: 3 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneAndroid />
                        </InputAdornment>
                      ),
                    }}
                    name={'login'}
                    label={'Login'}
                  />

                  <FormTextField
                    sx={{ width: '100%', mt: 3 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      ),
                    }}
                    type={'password'}
                    name={'password'}
                    label={'Password'}
                  />
                  <Button
                    sx={{
                      mt: 3,
                      py: 2,
                      backgroundColor: 'secondary.main',
                      color: 'white',
                    }}
                    onClick={handleSubmit}
                  >
                    Soumettre
                  </Button>
                </FormControl>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Access;
