import { styled } from '@mui/material';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { getCurrentUser } from '../services/authService';

import NavSide from './navSide';
import RightSide from './rightSide';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: '#f9fafb',
  flex: 1,
});

const Main = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

function Layout() {
  const checkUser = () => {
    const user = getCurrentUser();
    if (!user) {
      window.location = '/';
    }
  };
  useEffect(() => {
    checkUser();
  }, []);
  return (
    <StyledRoot>
      <NavSide />
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}

export default Layout;
