import { Box } from '@mui/material';
import React from 'react';

function Logo() {
  return (
    <Box sx={{ padding: 1 }}>
      <img
        width={'100%'}
        height={50}
        src={require('../../assets/images/logo.png')}
        alt={'logo'}
      />
    </Box>
  );
}

export default Logo;
