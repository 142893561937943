import {
  Button,
  FormHelperText,
  Grid,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import { Box, Container } from '@mui/system';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import FormTextField from '../../../components/FormTextField';

export default function StepThree({
  handleBack,
  handleNext,
  handleSetData,
  data,
}) {
  const stepThreeSchema = Yup.object().shape({
    societeNom: Yup.string().required('ce champ est requis'),
    societeAdresse: Yup.string().required('ce champ est requis'),
    societeTel: Yup.string().required('ce champ est requis'),
    societeMail: Yup.string()
      .email('email invalide')
      .required('ce champ est requis'),
    responsableNom: Yup.string().required('ce champ est requis'),
    responsableTel: Yup.string().required('ce champ est requis'),
    responsableMail: Yup.string()
      .email('email invalide')
      .required('ce champ est requis'),
  });
  const initialState = { ...data };

  const Submit = async (data) => {
    try {
      console.log(data);
      handleSetData(data);
      handleNext();
    } catch (error) {
      console.log(error.response.data);
    }
  };

  return (
    <>
      <Container component="main" maxWidth="sm">
        <Formik
          validationSchema={stepThreeSchema}
          initialValues={initialState}
          onSubmit={(value) => Submit(value)}
        >
          {({
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            values,
            errors,
            touched,
          }) => (
            <Grid container spacing={1} component="main" maxWidth="sm">
              <Grid item xs={12} sm={12} sx={{ marginTop: 2 }}>
                <Typography>Nom de votre Societe :</Typography>
                <FormTextField name="societeNom" />
              </Grid>
              <Grid item xs={12} sm={12} sx={{ marginTop: 2 }}>
                <Typography>Siege :</Typography>
                <FormTextField name="societeAdresse" />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ marginTop: 2 }}>
                <Typography>Telephone :</Typography>
                <FormTextField name="societeTel" />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ marginTop: 2 }}>
                <Typography>Email :</Typography>
                <FormTextField name="societeMail" />
              </Grid>
              <Grid item xs={12} sm={12} sx={{ marginTop: 2 }}>
                <Typography>Nom du responsable :</Typography>
                <FormTextField name="responsableNom" />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ marginTop: 2 }}>
                <Typography>Telephone du responsable :</Typography>
                <FormTextField name="responsableTel" />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ marginTop: 2 }}>
                <Typography>Email du responsable :</Typography>
                <FormTextField name="responsableMail" />
              </Grid>
              <Stack
                width={'100%'}
                justifyContent={'space-between'}
                direction="row"
              >
                <Button
                  onClick={handleBack}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, mr: 2, p: 2 }}
                >
                  Retour
                </Button>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, p: 2, backgroundColor: 'secondary.main' }}
                >
                  Suivant
                </Button>
              </Stack>
            </Grid>
          )}
        </Formik>
      </Container>
    </>
  );
}
