import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { grey } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';

import './App.css';
import Layout from './layout/layout';
import Router from './routes';
import { ToastContainer } from 'react-toastify';
import { Box, GlobalStyles } from '@mui/material';
const theme = createTheme({
  palette: {
    primary: {
      main: '#282d47',
    },
    secondary: {
      main: '#4dac4d',
    },
    neutral: {
      main: grey[50],
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: 'white',
          backgroundColor: '#282d47',
          padding: 2,
        },
        body: {
          padding: 16,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            '&&': {
              color: '#4dac4d',

              '& * ': {
                color: '#4dac4d',
              },
            },
          },
        },
      },
    },
  },
});

function App() {
  return (
    <Box sx={{ flex: 1, display: 'flex' }}>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <GlobalStyles
          styles={{
            body: { display: 'flex', flex: 1 },
          }}
        />
        <BrowserRouter>
          <Router>
            <Layout />
          </Router>
        </BrowserRouter>
      </ThemeProvider>
    </Box>
  );
}

export default App;
