import axios from 'axios';
import { toast } from 'react-toastify';
import { setTokenHeader } from './tokenService';

const TOKEN_NAME = 'token';
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response.data.error.message &&
    error.response.data.error.status >= 400 &&
    error.response.data.error.status < 500;
  console.log(expectedError);
  if (expectedError) {
    toast.error(error.response.data.error.message);
  } else {
    toast.error('An unexpected error occurrred.');
  }
});
axios.defaults.headers.common['x-auth-token'] = setTokenHeader(TOKEN_NAME);

export default {
  get: axios.get,
  put: axios.put,
  delete: axios.delete,
  post: axios.post,
};
