import * as React from 'react';
import {
  Box,
  Stepper,
  Step,
  StepButton,
  Button,
  Typography,
} from '@mui/material';

import {
  StepFour,
  StepOne,
  StepThree,
  StepTwo,
  StepFive,
} from '../section/@dashboard/register';
import Logo from '../components/logo/Logo';
import CssBaseline from '@mui/material/CssBaseline';

const steps = [
  'Creation de votre compte',
  'A porpos de votre societe',
  'Validation',
  'Remerciement',
];

export default function Register() {
  const [allData, setAllData] = React.useState({
    name: '',
    firstname: '',
    email: '',
    photo: '',
    password: '',
    confirmPass: '',
    societeNom: '',
    societeAdresse: '',
    societeTel: '',
    societeMail: '',
    responsableNom: '',
    responsableTel: '',
    responsableMail: '',
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;
  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleSetData = (data) => {
    setAllData({
      ...allData,
      ...data,
    });
  };

  const renderStep = [
    <StepOne
      key={'stepOne'}
      handleNext={handleNext}
      data={allData}
      handleSetData={handleSetData}
    />,
    <StepTwo
      key={'stepTwo'}
      handleBack={handleBack}
      handleNext={handleNext}
      data={allData}
      handleSetData={handleSetData}
    />,
    <StepThree
      key={'stepThree'}
      handleBack={handleBack}
      handleNext={handleNext}
      request={allData}
      handleSetData={handleSetData}
    />,
    <StepFour
      handleNext={handleNext}
      key={'stepFour'}
      handleBack={handleBack}
      request={allData}
    />,
  ];
  return (
    <Box sx={{ width: '70%', height: '100%', margin: 'auto' }}>
      <CssBaseline />
      <Box sx={{ width: '18%', m: 'auto' }}>
        <Logo />
      </Box>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="red" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>

      <Box>{renderStep[activeStep]}</Box>
    </Box>
  );
}
