import {
  AddRounded,
  Lock,
  ManageAccountsRounded,
  Phone,
  PhoneAndroid,
  SupervisedUserCircle,
} from '@mui/icons-material';
import { filter } from 'lodash';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';

import Scrollbar from '../components/scrollbar/index';
import {
  AccessListHead,
  AccessListToolbar,
} from '../section/@dashboard/access/index';
import compteReducer from '../redux/reducer/compteReducer';
import httpService from '../services/httpService';
import { urlapi } from '../config/url';
import { ADD_ACCESS, FETCH_ACCOUNT } from '../redux/actions/actionsType';
import FormSelect from '../components/FormSelect';
import FormTextField from '../components/FormTextField';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { getStyleValue } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (access) =>
        access._id.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        access.compte.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  { id: 'nom', label: 'Nom Campagne', alignRight: false },
  { id: 'numero', label: 'Numero campagne', alignRight: false },
  { id: 'totalovh', label: 'Total ovh', alignRight: false },
  { id: 'totalClient', label: 'Montant client', alignRight: false },
];
function Consommation() {
  const [data, setData] = React.useState([]);
  const [load, setload] = useState({ status: false, progress: 0 });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, email) => {
    const selectedIndex = selected.indexOf(email);

    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredConsommation = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  );
  const handleChangeDate = ({ currentTarget: { value } }) => {
    getAllConsommation(value);
  };

  const isNotFound = !filteredConsommation.length && !!filterName;

  const getAllConsommation = async (date) => {
    setload({ ...load, status: true });
    try {
      const { data } = await httpService.post(
        `${urlapi}/allConsommation`,
        {
          fromdate: date,
        },
        {
          onDownloadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setload({ ...load, progress: percentCompleted });
          },
        }
      );
      setData([...data.consommation]);
      setload({ ...load, status: false });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
      <TextField
        format="DD-MM-YYYY"
        label="Date"
        type="date"
        defaultValue={new Date().toISOString().split('T')[0].replace('/', '-')}
        sx={{ width: 220 }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleChangeDate}
      />
      {load.status ? (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            // transform: 'translate(-50%,-50%)',
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Card sx={{ borderRadius: 3, mt: 5, boxShadow: 3 }}>
          <Box>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <AccessListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                />
                <TableBody>
                  {filteredConsommation
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { nom, numero, totalovh, totalClient } = row;
                      const selectedConsommation =
                        selected.indexOf(numero) !== -1;
                      return (
                        <TableRow
                          hover
                          key={nom}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedConsommation}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              sx={{ color: 'secondary.main' }}
                              checked={selectedConsommation}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {nom}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{`${numero}`}</TableCell>
                          <TableCell align="left">{totalovh}</TableCell>
                          <TableCell align="left">{totalClient}</TableCell>
                        </TableRow>
                      );
                    })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      )}
    </Box>
  );
}

export default Consommation;
