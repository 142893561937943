import { AddRounded, Euro } from '@mui/icons-material';
import { faker } from '@faker-js/faker';
import { filter } from 'lodash';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';

import Scrollbar from '../components/scrollbar/index';
import {
  SoldeListHead,
  SoldeListToolbar,
} from '../section/@dashboard/solde/index';
import compteReducer from '../redux/reducer/compteReducer';
import { urlapi } from '../config/url';
import httpService from '../services/httpService';
import { ADD_SOLDE, FETCH_ACCOUNT } from '../redux/actions/actionsType';
import * as Yup from 'yup';
import { Formik } from 'formik';
import FormTextField from '../components/FormTextField';
import { toast } from 'react-toastify';
import FormSelect from '../components/FormSelect';
import {
  AccessListHead,
  AccessListToolbar,
} from '../section/@dashboard/access';
import ReactApexChart from 'react-apexcharts';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (cdr_element) =>
        cdr_element.callingNumber.toLowerCase() === query.toLowerCase() ||
        cdr_element.calledNumber.toLowerCase() === query.toLowerCase() ||
        cdr_element.date.toLowerCase() === query.toLowerCase() ||
        cdr_element.duration.toLowerCase() === query.toLowerCase()
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
function Dashboard() {
  const [data, setData] = useState([]);
  const [consommationPearDate, setConsommationPearDate] = useState([]);
  const [dateArray, setDateArray] = useState([]);
  const [load, setload] = useState({ status: false, progress: 100 });
  const TABLE_HEAD = [
    { id: 'id', label: 'id', alignRight: false },
    { id: 'date', label: 'date', alignRight: false },
    {
      id: 'destinationCountry',
      label: 'destinationCountry',
      alignRight: false,
    },
    {
      id: 'destinationType',
      label: 'destinationType',
      alignRight: false,
    },
    { id: 'direction', label: 'direction', alignRight: false },
    { id: 'billingNumber', label: 'billingNumber', alignRight: false },
    { id: 'callingdNumber', label: 'callingNumber', alignRight: false },
    { id: 'calledNumber', label: 'calledNumber', alignRight: false },

    {
      id: 'duration',
      label: 'duration',
      alignRight: false,
    },
    { id: 'price', label: 'price', alignRight: false },
  ];

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(1000);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, number) => {
    const selectedIndex = selected.indexOf(email);

    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, number);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setload({ ...load, status: true });
    try {
      const { data } = await httpService.get(`${urlapi}/allData`, {
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          const percent = percentCompleted;
          setload({ status: true, progress: percent });
        },
      });
      setData([...data.mydata]);
      setDateArray([...data.dateArray]);
      setConsommationPearDate([...data.consommationPearDate]);
      setload({ ...load, status: false });
    } catch (error) {
      console.log(error);
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredCdr = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredCdr.length && !!filterName;

  return load.status ? (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '50%',
      }}
    >
      <LinearProgress
        variant="buffer"
        value={load.progress}
        valueBuffer={load.progress + 2}
      />
      <Typography marginY={5} variant="body2" textAlign={'center'}>
        Veuillez patienter lors du telechargement des donnes,
        <br /> cela peux prendre un temps considerable dependant de la taille du
        fichier a telecharger
      </Typography>
    </Box>
  ) : (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Typography fontSize={26} fontWeight={'bold'}>
        Dashboard
      </Typography>
      <ReactApexChart
        options={{
          chart: {
            id: 'basic-line',
          },
          xaxis: {
            categories: [...dateArray],
          },
        }}
        series={[
          {
            name: 'consommation par date',
            data: [...consommationPearDate],
          },
        ]}
        type="line"
        height={350}
      />
      <Card sx={{ borderRadius: 3, mt: 5, boxShadow: 3 }}>
        <AccessListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />
        <Box>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <AccessListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={filteredCdr.length}
                numSelected={selected.length}
              />
              <TableBody>
                {filteredCdr
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      id,
                      billingNumber,
                      calledNumber,
                      callingNumber,
                      date,
                      destinationCountry,
                      destinationType,
                      direction,
                      duration,
                      price,
                    } = row;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">{id}</TableCell>
                        <TableCell align="left">{date}</TableCell>
                        <TableCell align="left">{destinationCountry}</TableCell>
                        <TableCell align="left">{destinationType}</TableCell>
                        <TableCell align="left">{direction}</TableCell>
                        <TableCell align="left">{billingNumber}</TableCell>
                        <TableCell align="left">{callingNumber}</TableCell>
                        <TableCell align="left">{calledNumber}</TableCell>
                        <TableCell align="left">{duration}</TableCell>
                        <TableCell align="left">{price}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <TablePagination
          rowsPerPageOptions={[1000, 5000, 10000, 50000, 100000]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
}

export default Dashboard;
