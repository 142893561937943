import { AddRounded, Euro } from '@mui/icons-material';
import { faker } from '@faker-js/faker';
import { filter } from 'lodash';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';

import Scrollbar from '../components/scrollbar/index';
import {
  SoldeListHead,
  SoldeListToolbar,
} from '../section/@dashboard/solde/index';
import compteReducer from '../redux/reducer/compteReducer';
import { urlapi } from '../config/url';
import httpService from '../services/httpService';
import { ADD_SOLDE, FETCH_ACCOUNT } from '../redux/actions/actionsType';
import * as Yup from 'yup';
import { Formik } from 'formik';
import FormTextField from '../components/FormTextField';
import { toast } from 'react-toastify';
import FormSelect from '../components/FormSelect';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (access) =>
        access._id.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        access.compte.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function Solde() {
  const [state, dispatch] = useReducer(compteReducer, []);
  const [load, setload] = useState({ status: false, progress: 0 });
  const TABLE_HEAD = [
    { id: 'id', label: 'id', alignRight: false },
    { id: 'soldecompte', label: 'Compte', alignRight: false },
    { id: 'solde', label: 'Solde', alignRight: false },
  ];

  const INITIAL_VALUES = {
    _id: '',
    solde: 0,
  };

  const SOLDE_SCHEMA = Yup.object().shape({
    _id: Yup.string().required(),
    solde: Yup.number().required(),
  });
  const fakeData = [...Array(24)].map((_, index) => ({
    _id: faker.i,
    nom: faker.name.lastName(),
    prenom: faker.name.firstName(),
    solde: faker.random.numeric(3),
  }));

  const [data, setData] = useState([...fakeData]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, email) => {
    const selectedIndex = selected.indexOf(email);

    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  useEffect(() => {
    getAllComptes();
  }, []);

  const getAllComptes = async () => {
    setload({ ...load, status: true });
    try {
      const { data } = await httpService.get(`${urlapi}/comptes/allCompte`, {
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setload({ ...load, progress: percentCompleted });
        },
      });
      dispatch({ type: FETCH_ACCOUNT, payload: data });
      setload({ ...load, status: false });
    } catch (error) {
      console.log(error);
    }
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - state.length) : 0;

  const filteredSolde = applySortFilter(
    state,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredSolde.length && !!filterName;
  const Submit = async (value, { resetForm }) => {
    dispatch({ type: ADD_SOLDE, payload: { ...value } });
    resetForm();
    await httpService.put(`${urlapi}/comptes/addSolde`, { ...value });
  };

  return load.status ? (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        // transform: 'translate(-50%,-50%)',
        width: '100%',
        height: '100%',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      sx={{
        padding: 2,
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
      }}
    >
      <Typography fontSize={26} fontWeight={'bold'}>
        Solde
      </Typography>
      <IconButton
        sx={{
          marginTop: 2,
          backgroundColor: 'secondary.main',
          color: 'white',
          paddingRight: 2,
          borderRadius: 1,
          fontSize: 16,
          width: '10%',
        }}
        onClick={handleOpen}
      >
        <AddRounded sx={{ color: 'white' }} />
        Ajouter
      </IconButton>

      <Card sx={{ borderRadius: 3, mt: 5, boxShadow: 3 }}>
        <SoldeListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          headLabel={TABLE_HEAD}
        />
        <Box>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <SoldeListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredSolde
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const { _id, nom, prenom, solde, consommation } = row;
                    const selectedAccess = selected.indexOf(_id) !== -1;
                    const currentSold = solde - consommation * 3;
                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={selectedAccess}
                        sx={{
                          backgroundColor:
                            currentSold < 100 ? 'error.main' : 'white',
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            sx={{ color: 'secondary.main' }}
                            checked={selectedAccess}
                            onChange={(event) => handleClick(event, id)}
                          />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            {/* <Avatar
                              alt={nom}
                              src={`${urlapi}/ressources/profile/${photo}`}
                            /> */}
                            <Typography variant="subtitle2" noWrap>
                              {_id}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{`${nom}  ${prenom}`}</TableCell>
                        <TableCell align="left">{currentSold} €</TableCell>
                      </TableRow>
                    );
                  })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={state.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Ajouter solde
          </Typography>
          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            <Formik
              initialValues={INITIAL_VALUES}
              onSubmit={Submit}
              validationSchema={SOLDE_SCHEMA}
            >
              {({ handleSubmit }) => (
                <FormControl sx={{ minWidth: '100%' }}>
                  <InputLabel htmlFor="grouped-native-select">
                    Compte
                  </InputLabel>
                  <FormSelect name={'_id'} myarray={state} show={'nom'} />
                  <FormTextField
                    sx={{ width: '100%', mt: 3 }}
                    label={'Solde'}
                    name={'solde'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Euro />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    sx={{
                      mt: 3,
                      py: 2,
                      backgroundColor: 'secondary.main',
                      color: 'white',
                    }}
                    onClick={handleSubmit}
                  >
                    Soumettre
                  </Button>
                </FormControl>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Solde;
