import {
  BeachAccess,
  Image,
  Logout,
  Message,
  Notifications,
  Person2Rounded,
  Work,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Stack,
  Toolbar,
  styled,
  Button,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: 4,

  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: theme.palette.secondary.main,
}));

AppBarTop.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

function AppBarTop({ handleOpen, open }) {
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorElem, setAnchorElem] = useState(null);
  const handleDrawerOpen = () => {
    handleOpen(true);
  };
  const toggleMenu = ({ currentTarget }) => {
    setOpenMenu(!openMenu);
    setAnchorElem(currentTarget);
  };
  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar sx={{ flex: 1 }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon sx={{ color: 'white' }} />
        </IconButton>

        <Stack
          padding={2}
          flex={1}
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Button
            href="/logout"
            sx={{ py: 1, px: 2, fontSize: 13 }}
            variant="contained"
            startIcon={<Logout />}
          >
            Deconnexion
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

NotificationsList.propTypes = {
  handleCloseMenu: PropTypes.func.isRequired,
  openMenu: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  anchorElem: PropTypes.any,
};

function NotificationsList({ handleCloseMenu, openMenu, anchorElem }) {
  return (
    <Popover
      open={Boolean(openMenu)}
      anchorEl={anchorElem}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          width: 360,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        },
      }}
    >
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper',
        }}
      >
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <Image />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Photos" secondary="Jan 9, 2014" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <Work />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Work" secondary="Jan 7, 2014" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <BeachAccess />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Vacation" secondary="July 20, 2014" />
        </ListItem>
      </List>
    </Popover>
  );
}

export default AppBarTop;
