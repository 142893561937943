import { Navigate, useRoutes } from 'react-router-dom';

import Layout from './layout/layout';
import Access from './pages/Access';
import Notfound from './pages/Notfound';
import Compte from './pages/Compte';
import Solde from './pages/Solde';
import Login from './pages/LoginPage';
import Register from './pages/Register';
import Numbers from './pages/Numbers';
import Client from './pages/Client';
import Logout from './pages/Logout';
import Consommation from './pages/Consommation';
import Dashboard from './pages/Dashboard';

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <Layout />,
      children: [
        { path: '', element: <Dashboard /> },
        { path: 'compte', element: <Compte /> },
        { path: 'access', element: <Access /> },
        { path: 'solde', element: <Solde /> },
        { path: 'number', element: <Numbers /> },
        { path: 'consommation', element: <Consommation /> },
      ],
    },
    {
      path: '/',
      element: <Login />,
    },

    {
      path: 'client',
      element: <Client />,
    },
    {
      path: 'logout',
      element: <Logout />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      children: [
        { element: <Navigate to="/dashboard" /> },
        { path: '404', element: <Notfound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
