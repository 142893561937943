import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MailIcon from '@mui/icons-material/Mail';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Box, IconButton, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';

import Logo from '../components/logo/Logo';
import NavItem from '../components/nav-items/NavItem';
import AppBarTop from './appBarTop';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: '',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  zIndex: 3,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: 3,
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  flexGrow: 1,
  boxSizing: 'border-box',
  flexDirection: 'column',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  '.MuiDrawer-paper': {
    zIndex: 1,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    flex: 1,
    flexDirection: 'column',
  },
}));

const LIST_ITEMS = [
  {
    name: 'Dashboard',
    icon: 'ri:dashboard-2-fill',
    url: '/dashboard/',
  },
  {
    name: 'Numeros',
    icon: 'material-symbols:phone-callback',
    url: '/dashboard/number',
  },
  {
    name: 'Compte',
    icon: 'ri:building-2-fill',
    url: '/dashboard/compte',
  },
  {
    name: 'Solde',
    icon: 'ri:money-euro-circle-fill',
    url: '/dashboard/solde',
  },
  {
    name: 'Consommation',
    icon: 'material-symbols:chart-data',
    url: '/dashboard/consommation',
  },
  {
    name: 'Access',
    icon: 'teenyicons:unlock-circle-solid',
    url: '/dashboard/access',
  },
];

// eslint-disable-next-line react/prop-types
function NavSide({ toggleDrawer }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      <AppBarTop toggleDrawer={toggleDrawer} open={open} handleOpen={setOpen} />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Logo />
          <IconButton
            sx={{ color: 'secondary.main' }}
            onClick={handleDrawerClose}
          >
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <Divider />
        <List>
          {LIST_ITEMS.map((item, index) => (
            <NavItem
              key={index}
              text={item.name}
              icon={item.icon}
              url={item.url}
            />
          ))}
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}

export default NavSide;
