import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ImageListItem,
  Link,
  Modal,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { Send } from '@mui/icons-material';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import httpService from '../../../services/httpService';
import { urlapi } from '../../../config/url';

const StyledCardMedia = styled('div')({
  position: 'relative',
  padding: 'calc(100% / 25)',
});

const StyledTitle = styled(Link)({
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  fontSize: '25px',
});
const StyledDescription = styled(Typography)({
  height: 16,
  marginTop: 2,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  color: 'secondary',
});
const StyleComment = styled(Typography)({
  height: 35,
  marginTop: 2,

  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  color: 'secondary',
});

const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: 150,
  objectFit: 'cover',
  borderRadius: '5px',
  border: 'none',
  boxShadow: 'none',
});

// ----------------------------------------------------------------------

StepFour.propTypes = {
  request: PropTypes.object.isRequired,
  index: PropTypes.number,
};

function StepFour({ request, handleBack, handleNext }) {
  const [isload, setisload] = useState(false);
  const {
    name,
    firstname,
    email,
    photo,
    password,
    societeNom,
    societeAdresse,
    societeTel,
    societeMail,
    responsableNom,
    responsableTel,
    responsableMail,
  } = request;
  const [open, setOpen] = useState(false);

  const [selected, setSelected] = useState({});
  const onOpenImg = (img) => {
    setOpen(true);
    setSelected(img);
  };

  const handleClose = () => setOpen(false);

  const onSubmit = async () => {
    console.log('ok');
    setisload(true);
    const data = new FormData();
    data.append('nom', name);
    data.append('prenom', firstname);
    data.append('password', password);
    data.append('email', email);
    data.append('societeNom', societeNom);
    data.append('societeAddresse', societeAdresse);
    data.append('societeTel', societeTel);
    data.append('societeEmail', societeMail);
    data.append('responsableTel', responsableTel);
    data.append('responsableNom', responsableNom);
    data.append('responsableEmail', responsableMail);
    data.append('photo', photo);
    const { data: response } = await httpService.post(
      `${urlapi}/users/driver/new`,
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    setisload(false);
    toast.success(response);
    handleNext();
    socket.emit('send_request');
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% / 2)',
    bgcolor: 'background.paper',
    boxShadow: 24,
  };
  return isload ? (
    <CircularProgress />
  ) : (
    <Grid container item xs={12} sm={12} md={12}>
      <Grid container>
        <Card
          sx={{
            height: '100%',
            border: 'none',
            boxShadow: 'none',
            margin: 'auto',
            width: '60%',
          }}
        >
          <Typography textAlign={'left'}>Profil de votre compte</Typography>
          <Stack py={5} direction={'row'} justifyContent={'space-between'}>
            <Box>
              <Typography>nom : {name}</Typography>
              <Typography>prenom : {firstname}</Typography>
              <Typography>email : {email}</Typography>
              <Typography>mot de passe : {password}</Typography>
            </Box>
            <Box>
              <Avatar
                src={URL.createObjectURL(photo)}
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          </Stack>
          <Divider />
          <Typography sx={{ mt: 2 }}>Societe </Typography>
          <Stack py={5} flexDirection={'row'} justifyContent={'space-between'}>
            <Box>
              <Typography>nom de la societe : {societeNom}</Typography>
              <Typography>adresse de la societe : {societeAdresse}</Typography>
              <Typography>Telephone : {societeTel}</Typography>
              <Typography>Email : {societeMail}</Typography>
            </Box>
            <Box>
              <Typography>Nom : {responsableNom}</Typography>
              <Typography>Numero : {responsableTel}</Typography>
              <Typography>Email : {responsableMail}</Typography>
            </Box>
          </Stack>
          <Stack justifyContent={'space-between'} direction="row">
            <Button
              onClick={handleBack}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mr: 2, mb: 2, p: 2 }}
            >
              Retour
            </Button>
            <Button
              onClick={onSubmit}
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                p: 2,
                backgroundColor: 'secondary.main',
              }}
            >
              Suivant
            </Button>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}

export default StepFour;
