import { Box, Button, IconButton, Link, Typography } from '@mui/material';
import MonitorIcon from '@mui/icons-material/Monitor';
import React, { useEffect, useState } from 'react';
import Iconify from '../components/iconify/Iconify';
import { getCurrentUser } from '../services/authService';
import { Stack } from '@mui/system';
import httpService from '../services/httpService';
import { urlapi } from '../config/url';
import {
  AccountCircleOutlined,
  Email,
  LocationCity,
  Logout,
  NearMe,
  Phone,
  PhoneAndroid,
} from '@mui/icons-material';

function Client() {
  const [compte, setCompte] = useState(null);
  const [solde, setSolde] = useState(0);
  useEffect(() => {
    const currentUser = getCurrentUser();
    checkuser(currentUser);
    getCompte(currentUser._id);
  }, []);
  const getCompte = async (id) => {
    try {
      const { data } = await httpService.get(
        `${urlapi}/comptes/mycompte/${id}`
      );
      // if (data.campagnes.length > 0) {
      //   const myCampagne = await Promise.all(
      //     data.campagnes.map(async (campagne) => {
      //       try {
      //         const { data } = await httpService.get(
      //           `${urlapi}/credential/${campagne.campagneNumero}`
      //         );

      //         return { ...campagne, ...data };
      //       } catch (error) {
      //         console.log(error);
      //         toast.error('Une erreur est survenue');
      //         return campagne;
      //       }
      //     })
      //   );
      //   data.campagne = [...myCampagne];
      // }

      setCompte(data);
      const currentSold = data.solde - 3 * data.consommation;
      setSolde(currentSold);
    } catch (error) {
      console.log(error);
    }
  };
  const checkuser = async (user) => {
    if (!user) {
      window.location = '/';
    }
  };

  return (
    compte && (
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <Box sx={{ backgroundColor: 'secondary.main' }}>
          <Stack p={2} flexDirection={'row'} justifyContent={'flex-end'}>
            <Button
              href="/logout"
              sx={{ p: 2 }}
              variant="contained"
              startIcon={<Logout />}
            >
              Deconnexion
            </Button>
          </Stack>
          <Box sx={{ py: '5%' }}>
            <Typography color={'white'} fontSize={40} textAlign={'center'}>
              Bonjour {`${compte.nom} ${compte.prenom}`}
            </Typography>
            <Box>
              <Typography textAlign={'center'}>
                Votre solde Telecom est de
              </Typography>

              <Typography
                color={'primary.main'}
                textAlign={'center'}
                variant="subtitle2"
                fontSize={32}
              >
                {solde} €
              </Typography>
              <Typography
                color={'white'}
                textAlign={'center'}
                variant="subtitle2"
              >
                {`Ce ${new Date()
                  .toISOString()
                  .slice(0, 19)
                  .replace('T', ' ')}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Stack justifyContent={'center'} flexDirection={'row'} display={'flex'}>
          {compte && compte.access.length > 0 ? (
            <Box sx={{ mb: 6 }}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 'bolder',
                  pt: 3,
                  textAlign: 'center',
                }}
              >
                Vos access
              </Typography>
              <Stack
                flexWrap={'wrap'}
                flexDirection={'row'}
                display={'flex'}
                justifyContent={'center'}
                columnGap={8}
                mb={3}
              >
                {compte.access.map((a) => (
                  <Box key={a._id}>
                    <Stack flexDirection={'row'} justifyContent={'center'}>
                      <Iconify icon={'eva:monitor-outline'} width={75} />
                    </Stack>
                    <Typography>Login : {a.login}</Typography>
                    <Typography>Password : {a.password}</Typography>
                  </Box>
                ))}
              </Stack>
              <Box>
                <Typography textAlign={'center'}>
                  {`connectez-vous sur  `}
                  <Link href="https://app.vicibox.fr">app.vicibox.fr</Link>
                </Typography>
              </Box>
            </Box>
          ) : (
            <Typography textAlign={'center'}>
              Vous n'avez pas encore d'access contacter le responsable.
            </Typography>
          )}
        </Stack>
        {/* <Typography
          sx={{
            fontSize: 20,
            fontWeight: 'bolder',
            textAlign: 'center',
          }}
          textAlign={'center'}
        >
          Vos campagnes
        </Typography>
        {compte && compte.campagnes.length > 0 ? (
          <Box sx={{ mb: 6 }}>
            <Stack
              flexWrap={'wrap'}
              flexDirection={'row'}
              display={'flex'}
              justifyContent={'center'}
              columnGap={8}
              mb={3}
            >
              {compte.campagnes.map((a) => (
                <Box key={a._id}>
                  <Stack flexDirection={'row'} justifyContent={'center'}>
                    <Iconify icon={'teenyicons:headset-solid'} width={75} />
                  </Stack>
                  <Typography>Nom : {a.campagneNom}</Typography>
                  <Typography>Numero {a.campagneNumero}</Typography>
                </Box>
              ))}
            </Stack>
          </Box>
        ) : (
          <Typography sx={{ mb: 6 }} textAlign={'center'}>
            Vous n'avez pas encore de compagne contacter le responsable.
          </Typography>
        )} */}
        <Box
          sx={{
            backgroundColor: 'primary.main',
            py: 6,
            color: 'white',

            width: '100%',
          }}
        >
          <Box sx={{ maxWidth: '80%', m: 'auto', textAlign: 'right' }}>
            <Typography textAlign={'left'}>Profil du compte</Typography>
            <Stack
              flexDirection={'row'}
              display={'flex'}
              justifyContent={'space-between'}
              flexWrap={'wrap'}
            >
              <Box>
                <Stack pt={2} flexDirection={'row'}>
                  <LocationCity />
                  <Typography>Societe {compte.societe.societeNom} </Typography>
                </Stack>
                <Stack pt={2} flexDirection={'row'}>
                  <NearMe />
                  <Typography>
                    Situe a, {compte.societe.societeAdresse}
                  </Typography>
                </Stack>
                <Stack pt={2} flexDirection={'row'}>
                  <Phone />
                  <Typography>Telephone {compte.societe.societeTel}</Typography>
                </Stack>
                <Stack pt={2} flexDirection={'row'}>
                  <Email />
                  <Typography>Email : {compte.societe.societeMail}</Typography>
                </Stack>
              </Box>
              <Box>
                <Box>
                  <AccountCircleOutlined />
                  <Typography>{`${compte.nom} ${compte.prenom}`}</Typography>
                </Box>
                <Box>
                  <PhoneAndroid />
                  <Typography>{compte.responsablePhone}</Typography>
                </Box>
                <Box>
                  <Email />
                  <Typography>{compte.email}</Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    )
  );
}

export default Client;
