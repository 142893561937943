import jwt from 'jwt-decode';

function getToken(name) {
  return localStorage.getItem(name);
}

function getCurrentUser(name) {
  const token = getToken(name);
  return token ? jwt(token) : null;
}

function deleteToken(name) {
  return localStorage.removeItem(name);
}
function setTokenHeader(name) {
  return getToken(name);
}

export { getToken, deleteToken, setTokenHeader, getCurrentUser };
