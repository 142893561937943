import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Formik } from 'formik';
import { NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import { IconButton, InputAdornment } from '@mui/material';
import { urlapi } from '../config/url';
import FormTextField from '../components/FormTextField';
import Iconify from '../components/iconify/Iconify';
import { getCurrentUser } from '../services/authService';
import Logo from '../components/logo/Logo';

const initial = {
  email: '',
  password: '',
};
const LOGIN_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .required('Votre email est requis')
    .email('Entrer un email valide')
    .label('Email'),
  password: Yup.string()
    .required('Votre mot de passe est requis')
    .min(6, 'Mot de passe trop courte')
    .label('Password'),
});
function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const checkUser = () => {
    const user = getCurrentUser();
    if (user && user.title === 'client') {
      window.location = '/client';
    } else if (user && user.title === 'Admin') {
      window.location = '/dashboard';
    }
  };
  React.useEffect(() => {
    checkUser();
  }, []);
  const handleSubmit = async (values, { resetForm }) => {
    const { data: token } = await axios.post(`${urlapi}/comptes/login`, values);
    localStorage.setItem('token', token);
    const user = getCurrentUser();
    if (user && user.title === 'client') {
      window.location = '/client';
    } else if (user && user.title === 'Admin') {
      window.location = '/dashboard';
    }
  };
  return (
    <Container
      sx={{
        py: 3,
        // boxShadow: 5,
        position: 'absolute',
        top: '25%',
        left: '35%',
      }}
      component="main"
      maxWidth="sm"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        <Box sx={{ m: 'auto' }} width={'40%'}>
          <Logo />
        </Box>
        <Formik
          initialValues={initial}
          validationSchema={LOGIN_SCHEMA}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit }) => (
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormTextField label={'Email Address'} name="email" />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            <Iconify
                              icon={
                                showPassword
                                  ? 'eva:eye-fill'
                                  : 'eva:eye-off-fill'
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label={'Password'}
                    name="password"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, py: 2, backgroundColor: 'secondary.main' }}
                onClick={handleSubmit}
              >
                Connexion
              </Button>
            </Box>
          )}
        </Formik>
      </Box>
    </Container>
  );
}

export default Login;
