import {
  Avatar,
  Badge,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import { NavLink } from 'react-router-dom';
import { AddAlert, AddCircle, CloudUpload, Warning } from '@mui/icons-material';
import { Formik, useFormikContext } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import FormTextField from '../../../components/FormTextField';
import { urlapi } from '../../../config/url';
import httpService from '../../../services/httpService';
import Iconify from '../../../components/iconify';

const PHONEREGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export default function StepOne({ handleNext, handleSetData, data }) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showconfirmPassword, setshowconfirmPassword] = React.useState(false);
  const inputRef = React.useRef(null);
  const stepOneSchema = Yup.object().shape({
    name: Yup.string().required('Ce champ est requis').label('Nom'),
    firstname: Yup.string().required('Ce champ est requis').label('Prenom'),

    photo: Yup.mixed(),
    email: Yup.string()
      .email("Saisir un email valide s'il vous plait")
      .required("L'email est requis")
      .label('Email'),
    password: Yup.string()
      .min(4, 'Mot de passe trop courte')
      .required('Le mot de passe est requis')
      .label('Password'),
    confirmPass: Yup.string()
      .oneOf([Yup.ref('password')], 'Votre mot de passe ne correspond pas!')
      .required('Ce champ est requis')
      .label('Password'),
    view: Yup.object().nullable(),
  });
  const initialState = { ...data };
  const handleClick = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    handleSetData({ photo: fileObj });
    event.target.value = null;
  };

  const Submit = async (data) => {
    try {
      handleSetData(data);
      handleNext();
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleChange = (event) => {
    setNumberStart(event.target.value);
  };

  return (
    <>
      <Container component="main" maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 'auto',
          }}
        >
          <Formik
            validationSchema={stepOneSchema}
            initialValues={initialState}
            onSubmit={(value) => Submit(value)}
          >
            {({
              handleSubmit,
              errors,
              setFieldValue,
              setFieldTouched,
              touched,
              values,
            }) => (
              <Box component="form" noValidate sx={{ mt: 3 }}>
                <Grid justifyContent={'center'} container position={'relative'}>
                  <Grid
                    alignItems={'stretch'}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    item
                    xs={12}
                    sm={4}
                    flex={'1 0 100%'}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        mx: 2,
                        boxShadow: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderTopLeftRadius: 16,
                        backgroundColor: 'primary.main',
                      }}
                    >
                      <input
                        style={{ display: 'none' }}
                        accept={'.jpg,.jpeg,.png'}
                        ref={inputRef}
                        type="file"
                        onChange={(e) => {
                          setFieldValue('photo', e.target.files[0]);
                          handleFileChange(e);
                        }}
                      />
                      <Button
                        onClick={(e) => {
                          setFieldTouched('photo');
                          handleClick(e);
                        }}
                      >
                        {data.photo ? (
                          <img
                            width={300}
                            height={300}
                            src={URL.createObjectURL(data.photo)}
                            alt={'you'}
                            style={{ borderRadius: '50%' }}
                          />
                        ) : (
                          <Avatar
                            sx={{
                              width: 300,
                              height: 300,
                              border: `2px  dashed white`,
                              color: 'primary.main',
                              p: 2,
                              backgroundColor: 'white',
                            }}
                            alt="You"
                          >
                            <CloudUpload />
                          </Avatar>
                        )}
                      </Button>
                      <Typography
                        mt={2}
                        textAlign={'center'}
                        variant="caption"
                        color={'white'}
                      >
                        Format *.jpg,*jepg,*png <br />
                        max : 3.1MB
                      </Typography>
                      <Typography></Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid
                      sx={{
                        py: 6,
                        pl: 4,
                        pr: 6,
                        boxShadow: 4,
                        borderTopRightRadius: 16,
                        borderBottomRightRadius: 16,
                      }}
                      container
                      columnSpacing={2}
                    >
                      <Grid item xs={12} sm={6}>
                        <FormTextField name={'name'} label={'Nom '} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormTextField name={'firstname'} label={'Prenom '} />
                      </Grid>
                      <Grid sx={{ mt: 2 }} item xs={12} sm={12}>
                        <FormTextField name={'email'} label={'Email'} />
                      </Grid>
                      <Grid sx={{ mt: 2 }} item xs={12}>
                        <FormTextField
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                                >
                                  <Iconify
                                    icon={
                                      showPassword
                                        ? 'eva:eye-fill'
                                        : 'eva:eye-off-fill'
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          label={'Mot de passe'}
                          name="password"
                        />
                      </Grid>
                      <Grid sx={{ mt: 2 }} item xs={12}>
                        <FormTextField
                          type={showconfirmPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setshowconfirmPassword(!showconfirmPassword)
                                  }
                                  edge="end"
                                >
                                  <Iconify
                                    icon={
                                      showconfirmPassword
                                        ? 'eva:eye-fill'
                                        : 'eva:eye-off-fill'
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          label={'Confirmation Mot de passe'}
                          name="confirmPass"
                        />
                        <Button
                          onClick={handleSubmit}
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{
                            mt: 2,
                            mb: 2,
                            py: 2,
                            backgroundColor: 'secondary.main',
                          }}
                        >
                          Suivant
                        </Button>
                        <Box sx={{ marginTop: 2 }}>
                          <NavLink to={'/login'}>Se connecter</NavLink>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Formik>
        </Box>
      </Container>
    </>
  );
}
