import { CheckCircle } from '@mui/icons-material';
import { Typography, Box } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

function StepFive() {
  return (
    <Stack alignSelf={'center'} flex={1} padding={10}>
      <CheckCircle
        fontSize="large"
        sx={{ alignSelf: 'center', width: 75, height: 75, marginY: 6 }}
        color="success"
      />
      <Typography
        sx={{ textAlign: 'center', fontSize: 50, fontWeight: 'bolder' }}
      >
        Merci !
      </Typography>

      <Typography sx={{ textAlign: 'center', marginY: 4 }}>
        Votre condidature a ete bien retenue, vous aurez une reponse dans les
        bref delais!
      </Typography>
    </Stack>
  );
}

export default StepFive;
