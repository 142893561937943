import { Box, FormHelperText, Select } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';

FormSelect.propTypes = {
  name: PropTypes.string,
  myarray: PropTypes.array,
  show: PropTypes.string,
};
function FormSelect({ name, myarray, show }) {
  const { setFieldValue, values, setFieldTouched, touched, errors } =
    useFormikContext();
  return (
    <Box sx={{ width: '100%' }}>
      <Select
        sx={{ width: '100%' }}
        native
        label="Comptes"
        value={values[name]}
        onChange={({ currentTarget }) =>
          setFieldValue(name, currentTarget.value)
        }
        onBlur={() => setFieldTouched(name, true)}
        error={Boolean(errors[name]) && Boolean(touched[name])}
      >
        {/* {console.log(Boolean(errors[name]))} */}
        <option aria-label="None" value="" />
        {myarray.map((element) => (
          <option key={element._id} value={element._id}>
            {element[show]}
          </option>
        ))}
      </Select>
      {Boolean(errors[name]) && Boolean(touched[name]) && (
        <FormHelperText sx={{ color: 'error.main' }}>
          {errors[name]}
        </FormHelperText>
      )}
    </Box>
  );
}

export default FormSelect;
