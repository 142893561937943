import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import propTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Iconify from '../iconify/Iconify';

NavItem.propTypes = {
  text: propTypes.string.isRequired,
  icon: propTypes.string,
  url: propTypes.string,
};

function NavItem({ text, icon, url }) {
  return (
    <ListItem
      component={Link}
      to={url}
      key={text}
      disablePadding
      sx={{ display: 'block', color: 'white' }}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {<Iconify icon={icon} />}
        </ListItemIcon>
        <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
}

export default NavItem;
