import {
  NEW_ACCOUNT,
  FETCH_ACCOUNT,
  ADD_SOLDE,
  ADD_ACCESS,
  ADD_CAMPAGNE,
} from '../actions/actionsType';
import { v4 as uuidv4 } from 'uuid';
export default function compteReducer(state, action) {
  switch (action.type) {
    case NEW_ACCOUNT:
      return [...state, { _id: uuidv4(), ...action.payload }];
    case FETCH_ACCOUNT:
      return [...action.payload];
    case ADD_SOLDE:
      console.log(action.payload);
      return state.map((compte) =>
        compte._id === action.payload._id
          ? {
              ...compte,
              solde:
                parseFloat(compte.solde) + parseFloat(action.payload.solde),
            }
          : compte
      );
    case ADD_ACCESS:
      return state.map((compte) =>
        compte._id === action.payload._id
          ? {
              ...compte,
              access: [...compte.access, { ...action.payload, _id: uuidv4() }],
            }
          : compte
      );
    case ADD_CAMPAGNE:
      return state.map((compte) =>
        compte._id === action.payload._id
          ? {
              ...compte,
              campagnes: [
                ...compte.campagnes,
                { ...action.payload, _id: uuidv4() },
              ],
            }
          : compte
      );
    case Fet:
      return state.map((compte) =>
        compte._id === action.payload._id
          ? {
              ...compte,
              campagnes: [
                ...compte.campagnes,
                { ...action.payload, _id: uuidv4() },
              ],
            }
          : compte
      );
    default:
      return state;
  }
}
