import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

function FormTextField({ label, name, children, ...other }) {
  const { errors, setFieldValue, setFieldTouched, touched, values } =
    useFormikContext();

  return (
    <TextField
      required
      fullWidth
      label={label}
      name={name}
      autoComplete={name}
      onBlur={() => setFieldTouched(name)}
      onChange={({ currentTarget: { value } }) => {
        setFieldValue(name, value);
      }}
      {...other}
      value={values[name]}
      helperText={errors[name] && touched[name] ? errors[name] : ''}
      error={errors[name] && touched[name]}
    >
      {children}
    </TextField>
  );
}

export default FormTextField;
