import {
  AccountCircle,
  AddRounded,
  Email,
  GroupWork,
  LocationOn,
  Lock,
  ManageAccountsRounded,
  Phone,
  PhoneAndroid,
  SupervisedUserCircle,
} from '@mui/icons-material';
import { filter } from 'lodash';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';

import Scrollbar from '../components/scrollbar/index';
import {
  AccessListHead,
  AccessListToolbar,
} from '../section/@dashboard/access/index';
import compteReducer from '../redux/reducer/compteReducer';
import { FETCH_ACCOUNT, NEW_ACCOUNT } from '../redux/actions/actionsType';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormTextField from '../components/FormTextField';
import httpService from '../services/httpService';
import { urlapi } from '../config/url';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate( -50%,-50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  height: '70%',
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (access) =>
        access._id.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        access.nom.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  { id: 'name', label: 'id', alignRight: false },
  { id: 'nom', label: 'Nom', alignRight: false },
  { id: 'respPhone', label: 'Telephone responsable', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  // { id: 'password', label: 'Mot de passe', alignRight: false },
  { id: 'societe', label: 'Nom de la societe', alignRight: false },
  { id: 'siege', label: 'Siege', alignRight: false },
  { id: 'phone', label: 'Telephone de la societe', alignRight: false },
  { id: 'societe-email', label: 'Email societe', alignRight: false },
];

const PHONE_REGEX =
  /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
const INITIAL_VALUES = {
  nom: '',
  prenom: '',
  email: '',
  responsablePhone: '',
  password: '',
  societeNom: '',
  societeMail: '',
  societeAdresse: '',
  societeTel: '',
};

const COMPTE_SCHEMA = Yup.object().shape({
  nom: Yup.string().required(),
  prenom: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string().required(),
  societeNom: Yup.string().required(),
  societeMail: Yup.string().email().required(),
  societeAdresse: Yup.string().required(),
  societeTel: Yup.string()
    .matches(PHONE_REGEX, 'invalide format de telephone')
    .required(),
  responsablePhone: Yup.string()
    .matches(PHONE_REGEX, 'invalide format de telephone')
    .required(),
});

function Compte() {
  const [load, setload] = useState({ status: false, progress: 100 });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [page, setPage] = useState(0);
  const [state, dispatch] = useReducer(compteReducer, []);

  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    getAllComptes();
  }, []);

  const getAllComptes = async () => {
    setload({ ...load, status: true });
    try {
      const { data } = await httpService.get(`${urlapi}/comptes/allCompte`, {
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          const percent = percentCompleted;
          setload({ ...load, progress: percent });
        },
      });
      dispatch({ type: FETCH_ACCOUNT, payload: data });
      setload({ ...load, status: false });
    } catch (error) {
      console.log(error);
    }
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = state.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, email) => {
    const selectedIndex = selected.indexOf(email);

    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - state.length) : 0;

  const filteredAccess = applySortFilter(
    state,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredAccess.length && !!filterName;

  const OnSubmit = async (value, { resetForm }) => {
    console.log(value);
    dispatch({
      type: NEW_ACCOUNT,
      payload: {
        nom: value.nom,
        email: value.email,
        prenom: value.prenom,
        password: value.password,
        responsablePhone: value.responsablePhone,
        societe: {
          societeNom: value.societeNom,
          societeAdresse: value.societeAdresse,
          societeTel: value.societeTel,
          societeMail: value.societeMail,
        },
      },
    });
    //http service
    try {
      const { data } = await httpService.post(
        `${urlapi}/comptes/register`,
        value,
        {
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            const percent = percentCompleted;
            setload({ ...load, progress: percent });
          },
        }
      );
      setload({ ...load, status: false });
    } catch (error) {
      console.log(error);
    }
    console.log(value);
    resetForm();
  };

  return load.status ? (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        // transform: 'translate(-50%,-50%)',
        width: '100%',
        height: '100%',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box sx={{ padding: 2, display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Typography fontSize={26} fontWeight={'bold'}>
        Comptes
      </Typography>
      <IconButton
        sx={{
          marginTop: 2,
          backgroundColor: 'secondary.main',
          color: 'white',
          paddingRight: 2,
          borderRadius: 1,
          fontSize: 16,
          width: '10%',
        }}
        onClick={handleOpen}
      >
        <AddRounded sx={{ color: 'white' }} />
        Ajouter
      </IconButton>

      <Card sx={{ borderRadius: 3, mt: 5, boxShadow: 3 }}>
        <AccessListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />
        <Box>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <AccessListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={state.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredAccess
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      _id,
                      nom,
                      prenom,
                      email,
                      societe: {
                        societeNom,
                        societeAdresse,
                        societeTel,
                        societeMail,
                      },
                      // password,
                      responsablePhone,
                    } = row;
                    const selectedAccess = selected.indexOf(_id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={selectedAccess}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            sx={{ color: 'secondary.main' }}
                            checked={selectedAccess}
                            onChange={(event) => handleClick(event, _id)}
                          />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {_id}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{`${nom} ${prenom}`}</TableCell>
                        <TableCell align="left">{responsablePhone}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        {/* <TableCell align="left">{password}</TableCell> */}
                        <TableCell align="left">{societeNom}</TableCell>
                        <TableCell align="left">{societeAdresse}</TableCell>
                        <TableCell align="left">{societeTel}</TableCell>
                        <TableCell align="left">{societeMail}</TableCell>
                      </TableRow>
                    );
                  })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={6}
                      sx={{
                        py: 3,
                      }}
                    >
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={state.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          overflow="auto"
          flex={1}
          flexDirection="column"
          display="flex"
          p={2}
          sx={style}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Nouveau Compte
          </Typography>
          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            <Formik
              initialValues={INITIAL_VALUES}
              validationSchema={COMPTE_SCHEMA}
              onSubmit={OnSubmit}
            >
              {({ handleSubmit }) => (
                <FormControl sx={{ minWidth: '100%' }}>
                  <Grid columnSpacing={1} container>
                    <Grid item xs={12} sm={6}>
                      <FormTextField
                        name={'nom'}
                        label={'Nom'}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircle />
                            </InputAdornment>
                          ),
                        }}
                        sx={{ width: '100%', mt: 3 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormTextField
                        name={'prenom'}
                        label={'Prenom'}
                        sx={{ width: '100%', mt: 3 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid columnSpacing={1} container>
                    <Grid item xs={12} sm={6}>
                      <FormTextField
                        name={'email'}
                        label={'E-Mail'}
                        sx={{ width: '100%', mt: 3 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormTextField
                        name={'responsablePhone'}
                        label={'Telephone du responsable'}
                        sx={{ width: '100%', mt: 3 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Phone />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <FormTextField
                    name={'password'}
                    label={'Mot de passe'}
                    sx={{ width: '100%', mt: 3 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      ),
                    }}
                    type={'password'}
                  />
                  <FormTextField
                    name={'societeNom'}
                    label="Nom de la societe"
                    sx={{ width: '100%', mt: 3 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <GroupWork />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormTextField
                    name={'societeAdresse'}
                    label="Siege"
                    sx={{ width: '100%', mt: 3 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationOn />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormTextField
                    name={'societeTel'}
                    label="Telephone de societe"
                    sx={{ width: '100%', mt: 3 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormTextField
                    name={'societeMail'}
                    label="E-mail de la societe"
                    sx={{ width: '100%', mt: 3 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    sx={{
                      mt: 3,
                      py: 2,
                      backgroundColor: 'secondary.main',
                      color: 'white',
                    }}
                    onClick={handleSubmit}
                  >
                    Soumettre
                  </Button>
                </FormControl>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Compte;
